import Head from "next/head";
import Header from "@/shared/components/Header";
import MetaTags from "@/shared/components/MetaTags";
import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import BannerHorizontalChico from "@/widgets/Banner/Templates/BannerHorizontalChico";
import PagegrowlerList from "@/widgets/Listado/Templates/PagegrowlerList";
import WidgetDetalleBonoMoneda from "@/shared/components/WidgetDetalleBonoMoneda";
import DetalleBonoMoneda from "@/shared/components/DetalleBonoMoneda";

const DetalleMoneda = (props) => {
    let menues = props.menu ? props.menu : [];
    const [open, setOpen] = useState(false);
    const {
        content
    } = props;

    return (
        <>
            <Head>
                <meta name="og:image" property="og:image" content={`${process.env.NEXT_PUBLIC_DOMAIN_URL}/img/img-default-share.jpeg`} />
                <meta name="title" content={`Cotizaciones de Monedas`} />
                <MetaTags metas={props.meta} />
            </Head>
            <Header menues={menues} isOpenSidePanel={(open) => setOpen(open)} />
            <div className={`${styles['parent']}`} itemScope itemType="https://schema.org/NewsArticle">
                <div className={`${styles['main-container']}`}>
                    {!props.preview && <BannerHorizontalChico bannerId={'top'} />}
                    <div className={`${styles['container']}`}>
                        <div className={`${styles['detail-left']}`}>
                            <DetalleBonoMoneda value={content} />
                        </div>
                        <div className={`${styles['detail-right']}`}>
                            <PagegrowlerList url='column-lilanews' />
                        </div>
                    </div>
                    <div className={`${styles['detail-full']}`}>

                    </div>
                </div>
            </div>
        </>
    )
}

export default DetalleMoneda;