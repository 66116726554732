import Templates from '@/widgets/index';
import useSWR, { mutate } from 'swr'

const PagegrowlerList = (props) => {
    const { widget, url, fallbackUrl, isMobile } = props
    
    const fetcher = async url => {

        const res = await fetch(url)

        if ((!res.ok || res.status === 202) && fallbackUrl) {

            const fallbackResult = await fetch(`/api/list/${fallbackUrl}`)
            if (!fallbackResult.ok) {
                const error = new Error('An error occurred while fetching the data with fallback url')
                error.info = await res.json()
                error.status = res.status
                throw error
            }
            return fallbackResult.json()
        }

        return res.json()
    }

    const { data, error, isLoading } = useSWR(`/api/list/${url}`, fetcher, { revalidateOnFocus: false })

    if (error) {
        return;
    }

    return (
        <>
            {
                !isLoading && data?.data?.length > 0 && data.data.map((el, index) => {
                    const Key = el.id || index;
                    const TemplateNameSubItems = Templates[!widget ? el.template : widget]
                    return <TemplateNameSubItems key={Key} {...el.params} { ...(isMobile && { isMobile }) }/>

                })
            }
        </>
    )
}

export default PagegrowlerList