import React from 'react';
import styles from './styles.module.css';

const WidgetDetalleBonoMoneda = ({ value }) => {

    return (
        value && <div className={`${styles['cotizaciones-grid']}`}>
            <div className={`${styles['row-titles']}`}>
                {Object.entries(value).map(([key, value]) => {
                    return <div key={key + value}>{key}</div>
                })}
            </div>
            <div className={`${styles['row-values']}`}>
                {Object.entries(value).map(([key, value]) => {
                    return <React.Fragment key={key}>
                        <div className={`${styles['strong']}`}>{key}</div>
                        <div>{value}</div>
                    </React.Fragment>
                })}
            </div>
        </div>

    )
}

export default WidgetDetalleBonoMoneda;