import React from 'react';
import styles from './styles.module.css';

const DetalleBonoMoneda = ({ value }) => {

    /*
    El objeto value es un array con multiples arrays, cada array interno hace referencia a una fila
    ej:
    [
        [{label: '', value: ''}], fila 1
        [{label: '', value: ''},{label: '', value: ''}], fila 2
        [{label: '', value: ''},{label: '', value: ''},{label: '', value: ''}, fila 3
        [{label: '', value: ''},{label: '', value: ''}], fila 4
    ]
    */
    return (
       
        <div className={`${styles['main-content']}`}>
             
            {value.map(el => {
                return <div className={`${styles['row-content']}`}>
                    {el.map(item => {
                        return 
                        <div className={`${styles['detail-content']}`}>
                            <h1 className={`${styles['value']}`}>{item.value}</h1>
                            <div className={`${styles['label']}`}>{item.label}</div>
                        </div>
                    })}
                </div>
            })}<a href="/monedas"><div className={`${styles['boton-volver']}`}>volver</div></a>

<div className={`${styles['cotizaciones-firma']}`}>
            Datos provistos por BYMA (*)
            <img src="https://www.ellitoral.com/collections/utiles-diseno/logo-byma-firma___67b76973215744001a6c129d.png" title="byma"/></div>
        </div>
        
    )
}

export default DetalleBonoMoneda;