import styles from './styles.module.css';
import Image from "@/shared/components/Image/new"; // Esto viene de destacada, importar img
import { formatAuthorNames } from '@/shared/functions/formatAthorNames';

const DestacadoImagenesVertical = (props) => {
    const {
        title,
        url,
        url_img1,
        link_img1,
        title_img1,
        url_img2,
        link_img2,
        title_img2,
        url_img3,
        link_img3,
        title_img3,
        url_img4,
        link_img4,
        title_img4,
        url_img5,
        link_img5,
        title_img5,
        isPreview,
        url_seccion
    } = props;

    let cabezal;

    if (title !== "" && url === "") {
        cabezal = <div className={styles['texto-video-nota']}>{title}</div>;
    } else if (title !== "" && url !== "") {
        cabezal = (
            <div className={styles['texto-video-nota']}>
                <a href={url}>{title}</a>
            </div>
        );
    }

    return (
        <div className={isPreview ? styles['preview-containter-imagen'] : styles['container-imagen']}>

            <div className={styles['contenedor-principal-stories']}>
                <div className={styles['row']} id="menu-principal-wstories">
                    <div className={styles["scroll-margin contenedor-secundario2-depo"]}>
                        <nav className={styles["menu-el-scroll"]}>
                            <ul>
                                <li>
                                    <a href={link_img1} target="_blank" rel="noopener noreferrer">
                                    {title_img1 && <div className={styles["titulo-tarjeta"]}>{title_img1}</div>}
                                        <img src={url_img1} alt={title_img1} />
                                        
                                    </a>
                                </li>
                                <li>
                                    <a href={link_img2} target="_blank" rel="noopener noreferrer">
                                    {title_img2 && <div className={styles["titulo-tarjeta"]}>{title_img2}</div>}
                                        <img src={url_img2} alt={title_img2} />
                                    </a>
                                </li>
                                <li>
                                    <a href={link_img3} target="_blank" rel="noopener noreferrer">
                                    {title_img3 && <div className={styles["titulo-tarjeta"]}>{title_img3}</div>}
                                        <img src={url_img3} alt={title_img3} />
             
                                    </a>
                                </li>
                                <li>
                                    <a href={link_img4} target="_blank" rel="noopener noreferrer">
                                    {title_img4 && <div className={styles["titulo-tarjeta"]}>{title_img4}</div>}
                                        <img src={url_img4} alt={title_img4} />
                                    
                                    </a>
                                </li>
                                <li>
                                    <a href={link_img5} target="_blank" rel="noopener noreferrer">
                                    {title_img5 && <div className={styles["titulo-tarjeta"]}>{title_img5}</div>}
                                        <img src={url_img5} alt={title_img5} />
                                        
                                    </a>
                                </li>
                            </ul>
                        </nav>
                        <div className={styles["ver-mas"]}>
                        <a href={url_seccion} target="_blank" rel="noopener noreferrer">Ver más </a>
                            <span className={styles["icon"]}>&gt;</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DestacadoImagenesVertical;
